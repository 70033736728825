import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='main-footer'>
     <div className="container">
     <div className="top">
        <div className="left">
          <Link className='link' target='_blank' to='https://www.manager.avopla.ca/'>
          Sign In
          </Link>
          <Link className='link' target='_blank' to='https://avopla.com/legal/privacy-policy'>
          Privacy policy
          </Link>
          <Link className='link' target='_blank' to='https://avopla.com/legal/terms-and-conditions'>
          Terms of use
          </Link>
          <Link to='/plans' className='link'>
          Our plans
          </Link>
        </div>
        <Link to='/registration' className="button">
        Contact us
        </Link>
      </div>
      <div className="copy">
      Avopla Inc. 2024 
      </div>
     </div>
    </div>
  )
}

export default Footer