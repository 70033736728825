import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import TopImage from '../../images/plans.svg'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Pricing = () => {

  const [loading,setLoading]=useState('')
  const [firstName,setFirstName]=useState('')
  const [lastName,setLastName]=useState('')
  const [email,setEmail]=useState('')
  const [phone,setPhone]=useState('')
  const [bestwaytocontact,setBestwaytocontact]=useState('')
  const [ownerormanager,setOwnerormanager]=useState('')
  const [businessPlan,setBusinessPlan]=useState('')
  const [restaurantName,setRestaurantName]=useState('')
  const [purposeOfContact,setPurposeOfContact]=useState('')
  const [restaurantAddress,setRestaurantAddress]=useState('')
  const [tableExpectation,settableExpectation]=useState('')
  const handlePlanChange = (event) => {
    setBusinessPlan(event.target.id); 
  };
  const registerFunction = async (data) => {
    let formData = new FormData()
    if(firstName){
      formData.append('first_name',firstName)
    }
    if(lastName){
      formData.append('last_name',lastName)
    }
    if(email){
      formData.append('email',email)
    }
    if(phone){
      formData.append('phone',phone)
    }
    if(bestwaytocontact){
      formData.append('best_way_to_contact',bestwaytocontact)
    }
    if(ownerormanager){
      formData.append('is_owner_or_manager',ownerormanager)
    }
    if(businessPlan){
      formData.append('fitting_business_plan',businessPlan)
    }
    if(restaurantName){
      formData.append('restaurant_name',restaurantName)
    }
    if(restaurantAddress){
      formData.append('restaurant_address',restaurantAddress)
    }
    if(purposeOfContact){
      formData.append('purpose_of_contact_us',purposeOfContact)
    }
    if(tableExpectation){
      formData.append('online_enable_table_expectation',tableExpectation)
    }
    setLoading(true)
try {
  const response =  await Services.registrationApi(formData);
  setLoading(false)
    
  
  // console.log(response);
  if(response.data.success === true){
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setBusinessPlan('')
    setBestwaytocontact('')
    setOwnerormanager('')
    setRestaurantAddress('')
    setRestaurantName('')
    setPurposeOfContact('')
    settableExpectation('')
    
      toast.success("email sent succesfuly",{
          autoClose:1500,
          pauseOnHover:true,
          draggable:true,
          
        });
       
  }
  
  else{
    setLoading(false)
   
    toast.error(response?.data?.errors[0],{
      autoClose:2000,
      pauseOnHover:true,
      draggable:true,
      
    });
  }
  
} catch (error) {
  setLoading(false)

  
  console.log('rrr',error)
  toast.error("some information missing",{
    autoClose:2000,
    pauseOnHover:true,
    draggable:true,
    
  });
}

 

}

  return (
    <div className="why-avopla-container">
          <ToastContainer />
      {loading && <div className="parent-loader">
        <div className="_loader"></div></div>}
    <div className="main-header mb-40">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="content">
                <h2 className="title">
                The easy way to manage your reservations
                <br />
                <span>Because every reservation count! </span>
                </h2>
              
                <div className="flex-30">
                  <Link className="button" to='/registration' style={{ background: '#CA2E54' }}>REGISTER RESTAURANT</Link>
                  
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="image-wrapper">
                <img src={TopImage} alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="price-wrapper mb-40">
        <h2 className="title uppercase">
        Ready to promote your restaurant 
        </h2>
        <h2 className="subtitle uppercase">
        Choose the plan that matches your needs 
        </h2>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="price-box">
              <div className="header">
                <p className="title text-center uppercase">
                Basic
                </p>
                <Link to='/registration' className="button">
                Get started
                </Link>
              </div>
              <div className="content">
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Get charged  based on percentage of your menu’s average price for each diners coming from Avopla
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Receive instant and illimited reservations
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  List and promote your restaurant profile and activate reservation tool
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Automate your reservations process
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Get access to your Guests book  
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Enjoy our EASY-TO-USE application to manage reservations 
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Use Dashboard for reporting and statistics to analyse your reservations datas
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Make offers on reservations that fits your restaurant strategy
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Automated review form to only diners who honored their reservations 
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Get reviews visibility and reply to your guests reviews
                  </div>
                 </div>
              </div>
              <div className="footer">
              Cancel anytime!
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="price-box readonly">
              <div className="header">
                <div className="comming-soon">
                Coming soon !
                </div>
                <p className="title text-center uppercase">
                Premium
                </p>
                <button className="button">
                Contact sales
                </button>
              </div>
              <div className="content">
                <p className="text">
                All Basic features included 
                </p>
                <div className="text-center mb-5">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 7.91669C21.15 7.91669 22.0833 8.85002 22.0833 10V17.9167H30C30.5525 17.9167 31.0824 18.1362 31.4731 18.5269C31.8638 18.9176 32.0833 19.4475 32.0833 20C32.0833 20.5526 31.8638 21.0825 31.4731 21.4732C31.0824 21.8639 30.5525 22.0834 30 22.0834H22.0833V30C22.0833 30.5526 21.8638 31.0825 21.4731 31.4732C21.0824 31.8639 20.5525 32.0834 20 32.0834C19.4474 32.0834 18.9175 31.8639 18.5268 31.4732C18.1361 31.0825 17.9166 30.5526 17.9166 30V22.0834H9.99996C9.44743 22.0834 8.91752 21.8639 8.52682 21.4732C8.13612 21.0825 7.91663 20.5526 7.91663 20C7.91663 19.4475 8.13612 18.9176 8.52682 18.5269C8.91752 18.1362 9.44743 17.9167 9.99996 17.9167H17.9166V10C17.9166 8.85002 18.85 7.91669 20 7.91669Z" fill="#025B51"/>
</svg>

                </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Table management and assignation
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Special event feature  
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Waitlist feature

                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Bad reviews alerting feature
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  POS integration 
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Credit cards holds 
                  </div>
                 </div>
                 <div className="item">
                  <div className="circle">

                  </div>
                  <div>
                  Guestbook export 
                  </div>
                 </div>
                 
              </div>
              <div className="footer">
              Cancel anytime!
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="container">
        <div className="faq-wrapper mb-40">
          <h2 className="title">Frequently asked questions</h2>
          <h2 className="subtitle">Find your answer quickly from that selected FAQ</h2>
          <div class="accordion accordion-flush my-4" id="faq-parent">
  <div class="accordion-item">
    <h2 class="accordion-header" >
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#one"  aria-controls="one">
      Does Avopla charges covers for No-show reservations? 
      </button>
    </h2>
    <div id="one" class="accordion-collapse "  data-bs-parent="#faq-parent">
      <div class="accordion-body">
      Avopla only charges cover fees for honored reservations that comes from Avopla’s plateforms (website and mobile application). The Cancelled, rejected and No-show reservations will not be charged for your restaurant. 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#two" aria-expanded="false" aria-controls="two">
      How do special offers works? 
      </button>
    </h2>
    <div id="two" class="accordion-collapse " aria-labelledby="flush-headingTwo" data-bs-parent="#faq-parent">
      <div class="accordion-body">
      The special offers are discounts you can enable exlusively for Avopla diners. Restaurant have full hand to make offers on the desired dates, times and party size.
      There is no obligation to propose the special offers to get listed on our search engine, however, having special discounts can improve your listing and increase your tables occupation rates.
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" >
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#three" aria-expanded="false" aria-controls="three">
      Is the reservation process automated?
      </button>
    </h2>
    <div id="three" class="accordion-collapse "  data-bs-parent="#faq-parent">
      <div class="accordion-body">
      The restaurant detailed page on Avopla plateforms, has a fully automated reservation process that helps guests making their reservations in some clics. When reservation is made, we do sent notifications to restaurant at the same time as the guest. Restaurant can check all upcoming and past reservations on the easy-to-use management plateform and has the hand to manage it and see all reservation and guest details.   
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" >
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="four">
      Can we customize our online reservation calendar? 
      </button>
    </h2>
    <div id="four" class="accordion-collapse "  data-bs-parent="#faq-parent">
      <div class="accordion-body">
      Avopla makes all processes easy and efficient. You will be responsible for your online availabilities add and edit as per as your restaurant goals. The reservations can be enabled using your own starting and ending date and time, the party size and available seats for each time shift. 
Each restaurant creates a unique reservation calendar to make a unique strategy, we are helping you improve your visibillity and increase your restaurants profits by increasing your table occupation rate. 
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" >
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="four">
      Is Avopla charging a monthly fees for basic plan? 
      </button>
    </h2>
    <div id="four" class="accordion-collapse "  data-bs-parent="#faq-parent">
      <div class="accordion-body">
      The basic plan offer free monthly fees. With the basic plan, we only charge for you a percentage based on your average price menu (calculated by our team based on your menu) on each cover coming from Avopla platfrorms (website and mobile applictaion). Only honoored reservations are charged. 
        </div>
    </div>
  </div>
 
           </div>
           <div className="d-flex justify-content-center">
            <button className="button">
            SEE all FAQs
            </button>

           </div>
        </div>
      </div>
      <div className="container">
      <div className="contact-wrapper">
          <h2 className="title">
          Tell us about your restaurant
          </h2>
          <p className="subtitle">
          We will be happy to discuss with you about your business needs. 
          </p>
          <p className="title-small">
          contact details
          </p>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">First name <sup>*</sup></label>
                <input type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)} placeholder='First name *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Last name <sup>*</sup></label>
                <input type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)} placeholder='Last name *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Your email address <sup>*</sup></label>
                <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Your email address *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Your phone number <sup>*</sup></label>
                <input type="text" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder='Your phone number *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">best way to contact you </label>
                <select name="" value={bestwaytocontact} onChange={(e)=>setBestwaytocontact(e.target.value)} id="" className="input">
                <option value="">Select</option>
                  <option value="By Email">By Email</option>
                  <option value="By Phone">By Phone</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">Do you own or manage the restaurant? </label>
                <select name="" value={ownerormanager} onChange={(e)=>setOwnerormanager(e.target.value)} id="" className="input">
                <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            
          </div>
          <div className="custom-radio-wrapper">
            <span className="title">
            Wich plan do you think fitting closer to your business needs? 
            </span>
            <div className="right">
              <div className="custom-radio">
                <input type="radio" onChange={handlePlanChange}
          checked={businessPlan == 'basic'} name="plan" id="basic" />
                <label htmlFor="basic">Basic plan</label>
              </div>
              <div className="custom-radio">
                <input type="radio" onChange={handlePlanChange}
          checked={businessPlan === 'premium'} name="plan" id="premium" />
                <label htmlFor="premium">Premium plan</label>
              </div>
            </div>

          </div>
          <p className="title-small">
          Restaurant details
          </p>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">your restaurant name <sup>*</sup></label>
                <input type="text" value={restaurantName} onChange={(e)=>setRestaurantName(e.target.value)} placeholder='your restaurant name *' className="input" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">your restaurant address <sup>*</sup></label>
                <input type="text" value={restaurantAddress} onChange={(e)=>setRestaurantAddress(e.target.value)} placeholder='your restaurant address *' className="input" />
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">The purpose of contacting us </label>
                <select name="" value={purposeOfContact} onChange={(e)=>setPurposeOfContact(e.target.value)} id="" className="input">
                <option value="">Select</option>
                  <option value="I want to learn about Avopla">I want to learn about Avopla</option>
                  <option value="I want to schedule a demo">I want to schedule a demo</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="">How many table you expect to enable online </label>
                <select name="" value={tableExpectation} onChange={(e)=>settableExpectation(e.target.value)} id="" className="input">
                  <option value="">Select</option>
                  <option value="5-10">5-10</option>
                  <option value="11-20">11-20</option>
                  <option value="21 +">21 +</option>
                </select>
              </div>
            </div>
            
          </div>
          <div className="d-flex justify-content-end">
            <button className="button" onClick={registerFunction}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing