import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import Layout from "./routes/layout/Layout";
import Pricing from "./routes/pricing/Pricing";
import WhyAvopla from "./routes/why-avopla/WhyAvopla";
import Registration from "./routes/registration/Registration";
function App() {


  
  const MainRoute =({children})=>{
    
      return <>{children}</>
    
  }

 
  const router = createBrowserRouter([
    

    
    {
      path: "/",
      element: <MainRoute> <Layout /> </MainRoute>,
      children:[
        {
          path: "", // This will render WhyAvopla as the default page for "/"
          element: <WhyAvopla />
        },
        {
          path:"/plans",
          element:<Pricing/>
        },
        {
          path:"/registration",
          element:<Registration/>
        },
       
        
        
      ]
    }
  ]);



  return (

    <RouterProvider router={router}/>
  );
}

export default App;
