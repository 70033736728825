import axios from 'axios';

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = 'https://connect.avopla.com/api'
// axios.defaults.headers.common['Authorization'] = 'Bearer'+AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';




async function registrationApi(data) {
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'post',
           url: `/restaurant-registration-request`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            // 'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
  } catch (err) {
    return err
  }
}
export const Services= { registrationApi}