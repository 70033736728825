import React from 'react'
import Logo from '../images/logo.svg'
import { Link } from 'react-router-dom'
const Navbar = () => {
  return (
    <div className="container">
    <div className='nav-bar-main'>
      
      <div className="left">
        <div className="logo">
          <img src={Logo} alt="" srcset="" />
        </div>
        <div className="links">
      <Link className="link" to='/'>Why Avopla?</Link>
      <Link className="link" to='/plans'>Pricing</Link>
        </div>
      </div>
      <Link className="button" to='https://www.manager.avopla.ca/' target='_blank'>Login</Link>
      
      </div>
      </div>
  )
}

export default Navbar