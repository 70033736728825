import React,{useEffect} from 'react'

import { useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const Layout = () => {
  const navigate = useNavigate()
//   const token = localStorage.getItem('token')
//   useEffect(()=>{
//     if(!token) {
//       navigate('/login')
//     }
//   },[token])
  return (
    <div className="layout">
    <div className="top-section">
      <Navbar />
    </div>
    <div className="main-content">
      <Outlet/>
    </div>
    <div className="bottom-section">
      <Footer />
    </div>
  </div>
  )
}

export default Layout